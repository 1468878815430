import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import Toolbox from '../Toolbox/Toolbox';
import Fontfamily from '../Toolbox/Fontfamily';
import Fontsize from '../Toolbox/Fontsize';
import Languages from '../Toolbox/Languages';
import MyContext from '../StoreContext';
import Spinner from '../Editor/Spinner';
import Ad from '../Editor/Ad';

export default function Editor() {
  const textareaRef = useRef(null);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(-1);
  const [fontStyle, setFontStyle] = useState({ fontFamily: 'Noto Sans, sans-serif', fontSize: '16px' });
  const [userLanguage, setUserLanguage] = useState('transliteration_en_ml');
  const [loading, setLoading] = useState(true);

  const langPlaceholder = [
    { lang: 'transliteration_en_hi', placeholder: 'यहां हिंदी लिख सकते हैं...' },
    { lang: 'transliteration_en_ml', placeholder: 'ഇവിടെ മലയാളം എഴുതാം...' },
    { lang: 'transliteration_en_bn', placeholder: 'এখানে বাংলা টাইপ করুন...' },
    { lang: 'transliteration_en_gu', placeholder: 'અહીં ગુજરાતી લખો...' },
    { lang: 'transliteration_en_kn', placeholder: 'ಇಲ್ಲಿ ಕನ್ನಡ ಎಂದು ಟೈಪ್ ಮಾಡಿ...' },
    { lang: 'transliteration_en_ta', placeholder: 'இங்கு தமிழ் என டைப் செய்யவும்...' },
    { lang: 'transliteration_en_te', placeholder: 'ఇక్కడ తెలుగు అని టైప్ చేయండి...' },
  ];

  const fetchTransliterations = async (text) => {
    try {
      const response = await axios.get('https://www.google.com/inputtools/request', {
        params: {
          ime: userLanguage,
          num: 5,
          cp: 0,
          cs: 0,
          ie: 'utf-8',
          oe: 'utf-8',
          app: 'jsapi',
          text: text,
        },
      });

      const data = response.data;
      let newSuggestions = [];

      if (data[0] === 'SUCCESS' && data[1] && data[1][0] && data[1][0][1]) {
        //setSuggestions(data[1][0][1]);
        newSuggestions = data[1][0][1];
        
        newSuggestions.push(text);
        setSuggestions(newSuggestions);
        setCurrentFocus(0);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching transliterations:', error);
      setSuggestions([]);
    }
  };

  const getCaretCoordinates = (element, position) => {
    const div = document.createElement('div');
    const copyStyle = getComputedStyle(element);

    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop];
    }

    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';
    div.style.wordWrap = 'break-word';
    div.textContent = element.value.substring(0, position);

    document.body.appendChild(div);

    const span = document.createElement('span');
    span.textContent = element.value.substring(position) || '.';
    div.appendChild(span);

    const { offsetTop: top, offsetLeft: left } = span;

    document.body.removeChild(div);

    return { top, left };
  };

  const insertWord = (word) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
  
    // Split the text into parts around the current cursor position
    const textBefore = value.substring(0, start);
    const textAfter = value.substring(end);
  
    // Find the start position of the last word before the cursor
    const lastSpaceIndex = textBefore.lastIndexOf(' ') + 1;
    const lastLineBreakIndex = textBefore.lastIndexOf('\n') + 1;
    const lastWordStart = Math.max(lastSpaceIndex, lastLineBreakIndex);
  
    // Remove the last word before the cursor
    const textBeforeWithoutLastWord = value.substring(0, lastWordStart);
  
    // Insert the selected word at the current cursor position
    const newValue = textBeforeWithoutLastWord + word + ' ' + textAfter;
  
    // Update the state with the new value
    setValue(newValue);
    setSuggestions([]);
    setCurrentFocus(-1);
  
    // Calculate the new cursor position
    const newStart = lastWordStart + word.length + 1; // Account for the inserted word length and space
  
    // Move the cursor to the end of the inserted word
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(newStart, newStart);
    }, 0);
  };
  

  // const handleInput = async () => {
  //   const textarea = textareaRef.current;
  //   const start = textarea.selectionStart;

  //   const text = textarea.value.toLowerCase();
  //   const textBeforeArray = Array.from(text.substring(0, start));
  //   const lastWord = textBeforeArray.join('').split(/\s+/).pop();
  //   if (lastWord) {
  //     await fetchTransliterations(lastWord);
  //   } else {
  //     setSuggestions([]);
  //   }
  // };

  const handleInput = async () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
  
    // Allow space to be inserted before processing
    setTimeout(async () => {
      const text = textarea.value.toLowerCase();
      const textBeforeArray = Array.from(text.substring(0, start));
      const lastWord = textBeforeArray.join('').split(/\s+/).pop();
  
      // Only fetch transliterations if the last word exists and is not a space
      if (lastWord && lastWord.trim() !== '') {
        await fetchTransliterations(lastWord);
      } else {
        setSuggestions([]);
      }
    }, 0);
  };
  

  // const handleKeyDown = (e) => {
  //   const items = document.querySelectorAll('.autocomplete-items div');
  //   const textarea = textareaRef.current;
  //   const start = textarea.selectionStart;
  //   const end = textarea.selectionEnd;

  //   if (e.key === 'ArrowDown') {
  //     setCurrentFocus((prevFocus) => (prevFocus + 1) % suggestions.length);
  //     e.preventDefault();
  //   } else if (e.key === 'ArrowUp') {
  //     setCurrentFocus((prevFocus) => (prevFocus - 1 + suggestions.length) % suggestions.length);
  //     e.preventDefault();
  //   } else if (e.key === 'Enter') {
  //     if (suggestions.length > 0 && currentFocus > -1 && items[currentFocus]) {
  //       e.preventDefault();
  //       insertWord(suggestions[currentFocus]);
  //     } else {
  //       // Allow new line
  //       const newValue = value.substring(0, start) + '\n' + value.substring(end);
  //       setValue(newValue);

  //       setTimeout(() => {
  //         textarea.setSelectionRange(start + 1, start + 1);
  //       }, 0);
  //     }
  //   } else if (e.key === ' ') {
  //     e.preventDefault();

  //     if (currentFocus > -1 && items[currentFocus]) {
  //       insertWord(suggestions[currentFocus]);
  //     } else {
  //       const newValue = value.substring(0, start) + ' ' + value.substring(end);
  //       setValue(newValue);

  //       setTimeout(() => {
  //         textarea.setSelectionRange(start + 1, start + 1);
  //       }, 0);
  //     }
  //   } else if (e.key === 'Escape') {
  //     setSuggestions([]);
  //   } else if (e.key === 'Backspace' || e.key === 'Delete') {
  //     e.preventDefault();

  //     if (start !== end) {
  //       const newValue = value.substring(0, start) + value.substring(end);
  //       setValue(newValue);
  //       setTimeout(() => {
  //         textarea.setSelectionRange(start, start);
  //       }, 0);
  //     } else if (e.key === 'Backspace' && start > 0) {
  //       const newValue = value.substring(0, start - 1) + value.substring(start);
  //       setValue(newValue);
  //       setTimeout(() => {
  //         textarea.setSelectionRange(start - 1, start - 1);
  //       }, 0);
  //     } else if (e.key === 'Delete' && start < value.length) {
  //       const newValue = value.substring(0, start) + value.substring(start + 1);
  //       setValue(newValue);
  //       setTimeout(() => {
  //         textarea.setSelectionRange(start, start);
  //       }, 0);
  //     }

  //     handleInput();
  //   }
  //   else if (e.ctrlKey && e.key === 'x') {
  //     // Handle cut (Ctrl+X)
  //     document.execCommand('cut');
  //     setTimeout(() => {
  //       handleInput();
  //     }, 0);
  //   } else if (e.ctrlKey && e.key === 'v') {
  //     // Handle paste (Ctrl+V)
  //     document.execCommand('paste');
  //     setTimeout(() => {
  //       handleInput();
  //     }, 0);
  //   }    
  // };

  const handleKeyDown = (e) => {
    const items = document.querySelectorAll('.autocomplete-items div');
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
  
    if (e.key === 'ArrowDown') {
      setCurrentFocus((prevFocus) => (prevFocus + 1) % suggestions.length);
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setCurrentFocus((prevFocus) => (prevFocus - 1 + suggestions.length) % suggestions.length);
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (suggestions.length > 0 && currentFocus > -1 && items[currentFocus]) {
        e.preventDefault();
        insertWord(suggestions[currentFocus]);
      } else {
        // Allow new line
        const newValue = value.substring(0, start) + '\n' + value.substring(end);
        setValue(newValue);
        setTimeout(() => {
          textarea.setSelectionRange(start + 1, start + 1);
        }, 0);
      }
    } else if (e.key === ' ') {
      e.preventDefault();
  
      if (suggestions.length > 0 && currentFocus > -1 && items[currentFocus]) {
        insertWord(suggestions[currentFocus]);
      } else {
        const newValue = value.substring(0, start) + ' ' + value.substring(end);
        setValue(newValue);
  
        setTimeout(() => {
          textarea.setSelectionRange(start + 1, start + 1);
        }, 0);
      }
    } else if (e.key === 'Escape') {
      setSuggestions([]);
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
  
      if (start !== end) {
        const newValue = value.substring(0, start) + value.substring(end);
        setValue(newValue);
        setTimeout(() => {
          textarea.setSelectionRange(start, start);
        }, 0);
      } else if (e.key === 'Backspace' && start > 0) {
        const newValue = value.substring(0, start - 1) + value.substring(start);
        setValue(newValue);
        setTimeout(() => {
          textarea.setSelectionRange(start - 1, start - 1);
        }, 0);
      } else if (e.key === 'Delete' && start < value.length) {
        const newValue = value.substring(0, start) + value.substring(start + 1);
        setValue(newValue);
        setTimeout(() => {
          textarea.setSelectionRange(start, start);
        }, 0);
      }
  
      handleInput();
    } else if (e.ctrlKey && e.key === 'x') {
      // Handle cut (Ctrl+X)
      document.execCommand('cut');
      setTimeout(() => {
        handleInput();
      }, 0);
    } else if (e.ctrlKey && e.key === 'v') {
      // Handle paste (Ctrl+V)
      document.execCommand('paste');
      setTimeout(() => {
        handleInput();
      }, 0);
    }
  };
  
  


  const handleClick = (e) => {
    if (e.target !== textareaRef.current) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    textarea.addEventListener('input', handleInput);
    textarea.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);

    setLoading(false);

    return () => {
      textarea.removeEventListener('input', handleInput);
      textarea.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, [suggestions, currentFocus, value]);

  return (
    <>
      {loading && <Spinner />}
      <div className="pc-container">
        <div className="pc-content">
          <div className="row">
            <div className="col-sm-2 d-flex flex-column align-items-center">
                <Ad />             
            </div>
            <div className="col-sm-8 mx-auto">
              <MyContext.Provider value={{ fontStyle, setFontStyle, value, userLanguage, setUserLanguage }}>
                <Toolbox>
                  <Languages />
                  <Fontfamily />
                  <Fontsize />
                </Toolbox>
              </MyContext.Provider>
              <div className="card">
                <div className="card-body">
                  <div className="autocomplete-container">
                    <textarea
                      ref={textareaRef}
                      className="custom-textarea"
                      placeholder={langPlaceholder.find((data) => data.lang === userLanguage).placeholder}
                      rows="4"
                      cols="100"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      style={fontStyle}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className="autocomplete-items"
                        style={{
                          top: `${getCaretCoordinates(textareaRef.current, textareaRef.current.selectionStart).top + 20}px`,
                          left: `${getCaretCoordinates(textareaRef.current, textareaRef.current.selectionStart).left}px`,
                        }}
                      >
                        {suggestions.map((word, index) => (
                          <div
                            key={index}
                            className={index === currentFocus ? 'active' : ''}
                            onClick={() => insertWord(word)}
                            onMouseOver={() => setCurrentFocus(index)}
                          >
                            {word}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 d-flex flex-column align-items-center">
              <Ad /> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
}
