import React from 'react'
import './tools.css'

export default function Toolbox({children}) {
  return (
        <div className="toolbar" id="clusterdev-toolbar">
            <div className="flex items-center gap-1">
                {children}
            </div>
        </div>     
  )
}
