import React from 'react'

export default function Leftnavigation() {
  return (   
    <nav className="pc-sidebar">
<div className="navbar-wrapper">
  <div className="m-header">
    <img
      src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
      alt=""
      className="logo-lg"
    />
  </div>
  <div className="navbar-content">
    <ul className="pc-navbar">
      <li className="pc-item pc-caption" style={{display: 'none'}}>
        <label>Today</label>
        <i className="ph-duotone ph-chart-pie" />
      </li>  
      <li className="pc-item" >
        <a
          href="http://corporatetechnologies.in/xe"
          className="pc-link" target="_blank" rel="noreferrer"
        >
          <span className="pc-mtext">Currency Converter</span>
        </a>
      </li>        
      <li className="pc-item" >
        <a
          href="http://corporatetechnologies.in/qrcode"
          className="pc-link" target="_blank" rel="noreferrer"
        >
          <span className="pc-mtext">QR Code Generator</span>
        </a>
      </li>
      <li className="pc-item" >
        <a
          href="https://corporatetechnologies.in/areaconverter"
          className="pc-link" target="_blank" rel="noreferrer"
        >
          <span className="pc-mtext">Area Convertor</span>
        </a>
      </li> 
      <li className="pc-item" >
        <a
          href="https://corporatetechnologies.in/barcode"
          className="pc-link" target="_blank" rel="noreferrer"
        >
          <span className="pc-mtext">Barcode Generator</span>
        </a>
      </li>
      <li className="pc-item" >
        <a
          href="https://corporatetechnologies.in/pdfconverter"
          className="pc-link" target="_blank" rel="noreferrer"
        >
          <span className="pc-mtext">PDF Converter</span>
        </a>
      </li>                        
    </ul>
  </div>
  <div className="card pc-user-card">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img
            src="https://cdn4.iconfinder.com/data/icons/a-s-social-set/256/mail-64.png"
            alt="user-image"
            className="user-avtar wid-45 rounded-circle"
          />
        </div>
        <div className="flex-grow-1 ms-3">
          <div className="dropdown">
            <a
              href="https://corporatetechnologies.in/"
              className="arrow-none" target="_blank" rel="noreferrer"
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-2">
                  <h6 className="mb-0">Contact</h6>
                  <small><a href="https://corporatetechnologies.in/" target="_blank" rel="noreferrer" className="text-muted">Business enquiry</a></small>
                </div>
                <div className="flex-shrink-0">
                  <div className="btn btn-icon btn-link-secondary avtar">
                    <i className="ph-duotone ph-arrow-line-right" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</nav>        
  )
}
