import React, {useState, useEffect, useRef, useContext} from 'react'
import MyContext from '../StoreContext';

// export default function Fontfamily({onFontStyleChange}) {
  export default function Languages() {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('transliteration_en_ml');
  const dropdownRef = useRef(null);
  const [dropdownLeft, setDropdownLeft] = useState(337);

  const { useLanguage, setUserLanguage, fontStyle, setFontStyle } = useContext(MyContext);

  const languages = [
    { name: 'Hindi', language: 'transliteration_en_hi' },    
    { name: 'Malayalam', language: 'transliteration_en_ml' },
    { name: 'Bengali', language: 'transliteration_en_bn' },
    { name: 'Gujarati', language: 'transliteration_en_gu' },
    { name: 'Kannada', language: 'transliteration_en_kn' },
    { name: 'Tamil', language: 'transliteration_en_ta' },
    { name: 'Telugu', language: 'transliteration_en_te' },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectLanguage = (lang) => {
    setSelectedLanguage(lang);
    setIsOpen(false);
    setUserLanguage(lang);
    setFontStyle({ ...fontStyle, fontFamily: 'Noto Sans, sans-serif' });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const updateDropdownPosition = () => {
    const viewportWidth = window.innerWidth;

    if (viewportWidth > 992) {
      setDropdownLeft(337);
    } 
    else {
      setDropdownLeft(200);
    }   
  };

  useEffect(() => {
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition); // Update position on resize

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, []);

  const selectedLanguageLabel = languages.find(lang => lang.language === selectedLanguage)?.name || 'Default';

  return (
    <div className="tooltip__has-tooltip" ref={dropdownRef}>


    <div 
      id="toolbar-font-options" 
      className="toolbar__dropdown-button w-[100px]"
      onClick={toggleDropdown}
    >
      <span className="text-sm ml-2">{selectedLanguageLabel}</span>
      <div className="chevron-down-icon toolbar__icon-button"></div>      
    </div>    
    <div className="tooltip">Languages</div>

    {isOpen && (
      <div className="ui-menu-dropdown" style={{ top: 76, left: dropdownLeft }}>
        <div className="w-[220px] toolbar__font-tool__dropdown__container" style={{ maxHeight: 400 }}>
          {languages.map((langu) => (
            <button 
              key={langu.name} 
              className="toolbar__dropdown__item" 
              onClick={() => handleSelectLanguage(langu.language)}
            >
              <div className={`w-6 h-6 mr-2 ${selectedLanguage === langu.language ? 'tick-icon' : ''}`} />
              <span className="text pl-1" style={{ fontFamily: 'Noto Sans Malayalam, sans-serif' }}>
                {langu.name}
              </span>
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
  )
}
