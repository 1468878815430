import './App.css';
import Editor from './Editor/Editor';
import Leftnavigation from './Leftnavigation/Leftnavigation';
function App() {
  return (
          <>
          <Leftnavigation />
          <Editor />  
          </>
  );
}

export default App;
