import React, {useContext} from 'react'
import MyContext from '../StoreContext';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

export default function Fontsize() {

  const { fontStyle, setFontStyle, value } = useContext(MyContext);

  const handleIncrement = () => {
    setFontStyle({ ...fontStyle, fontSize: `${parseInt(fontStyle.fontSize) + 1}px` });
  }

  const handleDecrement = () => {
    setFontStyle({ ...fontStyle, fontSize: `${parseInt(fontStyle.fontSize) - 1}px` });
  }

  const exportWord = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [new TextRun(value)],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "document.docx");
    });
  };

  return (
    <>
    <div className="tooltip__has-tooltip">
        <div id="toolbar-font-sizes" className="toolbar__dropdown-button w-[70px] ml-1">
        <div className="minus-icon toolbar__icon-button toolbar__dropdown-button__icon" onClick={handleDecrement}></div>
        <div className="toolbar__font-size-edit" contentEditable="true" suppressContentEditableWarning={true}>{parseInt(fontStyle.fontSize, 10)}</div>
        <div className="add-icon toolbar__icon-button toolbar__dropdown-button__icon" onClick={handleIncrement}></div>
        </div>
        <div className="tooltip">Font Size</div>
    </div>
    <div className="flex gap-0.5 sm:gap-0" >
    <div className="toolbar__divider"></div>
      <div className="tooltip__has-tooltip">
         <div className={ value.trim() === '' ? "toolbar__item pointer-events-none opacity-50 !cursor-not-allowed" : "toolbar__item "} >
            <div className="msword-icon cursor-pointer" onClick={exportWord}></div>
         </div>
         <div className="tooltip">Export to Ms Word</div>
      </div> 
      <div className="tooltip__has-tooltip" style={{display:'none'}}>
         <div className={ value.trim() === '' ? "toolbar__item pointer-events-none opacity-50 !cursor-not-allowed" : "toolbar__item "}>
            <div className="voice-icon cursor-pointer"></div>
         </div>
         <div className="tooltip">Export to Speech</div>
      </div>            
    </div>   
    </>
  )
}
