import React, {useState, useEffect, useRef, useContext} from 'react'
import MyContext from '../StoreContext';
import { malayalamFonts, hindiFonts, tamilFonts, kannadaFonts, bengaliFonts, teluguFonts, gujaratiFonts } from '../Utils/Fonts';

  export default function Fontfamily() {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFont, setSelectedFont] = useState('Noto Sans, sans-serif');
  const dropdownRef = useRef(null);
  const [dropdownLeft, setDropdownLeft] = useState(337);

  const { fontStyle, setFontStyle, userLanguage } = useContext(MyContext);
  //const fonts = userLanguage === 'transliteration_en_ml' ? malayalamFonts : hindiFonts;
  let fonts = malayalamFonts;

  if ( userLanguage === 'transliteration_en_ml' ) {
    fonts = malayalamFonts;
  }
  else if( userLanguage === 'transliteration_en_hi' ) {
    fonts = hindiFonts;
  }
  else if ( userLanguage === 'transliteration_en_ta' ) {
    fonts = tamilFonts;
  }
  else if ( userLanguage === 'transliteration_en_kn' ) {
    fonts = kannadaFonts;
  }
  else if ( userLanguage === 'transliteration_en_bn' ) {
    fonts = bengaliFonts;
  }
  else if ( userLanguage === 'transliteration_en_te' ) {
    fonts = teluguFonts;
  } 
  else {
    fonts = gujaratiFonts;
  }        

  // useEffect(() => {
  //   // Set the default font when userLanguage changes
  //   setSelectedFont('Noto Sans, sans-serif');
  //   console.log(userLanguage);
  //   console.log(selectedFont);
  // }, [userLanguage]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectFont = (fontName) => {
    setSelectedFont(fontName);
    setIsOpen(false);
    setFontStyle({ ...fontStyle, fontFamily: fontName });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  const updateDropdownPosition = () => {
    const viewportWidth = window.innerWidth;
    console.log('Window width:', viewportWidth);

    if (viewportWidth > 992) {
      setDropdownLeft(337);
    } 
    else {
      setDropdownLeft(200);
    }   
  };

  useEffect(() => {
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition); // Update position on resize

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, []);

  const selectedFontLabel = fonts.find(font => font.fontFamily === selectedFont)?.label || 'Default';

  return (
    <div className="tooltip__has-tooltip" ref={dropdownRef}>
    <div 
      id="toolbar-font-options" 
      className="toolbar__dropdown-button w-[100px]"
      onClick={toggleDropdown}
    >
      <span className="text-sm ml-2">{selectedFontLabel}</span>
      <div className="chevron-down-icon toolbar__icon-button"></div>
    </div>
    <div className="tooltip">Fonts</div>

    {isOpen && (
      <div className="ui-menu-dropdown" style={{ top: 76, left: dropdownLeft }}>
        <div className="w-[220px] toolbar__font-tool__dropdown__container" style={{ maxHeight: 400 }}>
          {fonts.map((font) => (
            <button 
              key={font.name} 
              className="toolbar__dropdown__item" 
              onClick={() => handleSelectFont(font.fontFamily)}
            >
              <div className={`w-6 h-6 mr-2 ${selectedFont === font.fontFamily ? 'tick-icon' : ''}`} />
              <span className="text pl-1" style={{ fontFamily: font.fontFamily }}>
                {font.label}
              </span>
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
  )
}
