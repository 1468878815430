import React from 'react'

export default function Ad() {
  return (
    <>
      <ins className="d-none d-sm-none d-md-none adsbygoogle"
        style={{display:"inline-block", width:"100%", height: "120px"}}
        data-ad-client="ca-pub-6195674039585428"
      data-ad-slot="2490082463"></ins>    
    </>
  )
}
