const malayalamFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Sans, sans-serif' },
    { name: 'Chilanka', label: 'Chilanka', fontFamily: 'Chilanka, sans-serif' },
    { name: 'Manjari', label: 'Manjari', fontFamily: 'Manjari, sans-serif' },
    { name: 'Anek', label: 'Anek', fontFamily: 'Anek Malayalam, sans-serif' },
    { name: 'Baloo Chettan', label: 'Baloo Chettan', fontFamily: 'Baloo Chettan, sans-serif' },
  ];

const hindiFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Sans, sans-serif' },
    { name: 'Hind', label: 'Hind', fontFamily: 'Hind, sans-serif' },
    { name: 'Rajdhani', label: 'Rajdhani', fontFamily: 'Rajdhani, sans-serif' },
    { name: 'Teko', label: 'Teko', fontFamily: 'Teko, sans-serif' },
    { name: 'Kalam', label: 'Kalam', fontFamily: 'Kalam, cursive' },
   ];

const tamilFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Serif Tamil, sans-serif' },
    { name: 'Pavanam', label: 'Pavanam', fontFamily: 'Pavanam, sans-serif' },
    { name: 'Anek', label: 'Anek', fontFamily: 'Anek Tamil, sans-serif' },
    { name: 'Kavivanar', label: 'Kavivanar', fontFamily: 'Kavivanar, sans-serif' },
    { name: 'Meera', label: 'Meera', fontFamily: 'Meera Inimai, sans-serif' },
   ]; 
   
   const kannadaFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Serif Kannada, sans-serif' },
    { name: 'Baloo Tamma', label: 'Baloo Tamma', fontFamily: 'Baloo Tamma 2, sans-serif' },
    { name: 'Anek', label: 'Anek', fontFamily: 'Anek Kannada, sans-serif' },
    { name: 'Tiro', label: 'Tiro', fontFamily: 'Tiro Kannada, sans-serif' },
    { name: 'Hubballi', label: 'Hubballi', fontFamily: 'Hubballi, sans-serif' },
   ]; 
   
   const bengaliFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Sans Bengali, sans-serif' },
    { name: 'Tiro Bangla', label: 'Tiro Bangla', fontFamily: 'Tiro Bangla, sans-serif' },
    { name: 'Baloo Da', label: 'Baloo Da', fontFamily: 'Baloo Da 2, sans-serif' },
    { name: 'Galada', label: 'Galada', fontFamily: 'Galada, sans-serif' },
    { name: 'Atma', label: 'Atma', fontFamily: 'Atma, sans-serif' },
   ];
   
   const teluguFonts = [
    { name: 'Default', label: 'Default', fontFamily: 'Noto Serif Telugu, serif' },
    { name: 'Mandali', label: 'Mandali', fontFamily: 'Mandali, sans-serif' },
    { name: 'Gurajada', label: 'Gurajada', fontFamily: 'Gurajada, sans-serif' },
    { name: 'Mallanna', label: 'Mallanna', fontFamily: 'Mallanna, sans-serif' },
    { name: 'Suranna', label: 'Suranna', fontFamily: 'Suranna, sans-serif' },
   ];  
   
   const gujaratiFonts = [
    { name: 'Noto Sans Gujarati', label: 'Noto Sans Gujarati', fontFamily: 'Noto Sans Gujarati, sans-serif' },
    { name: 'Rasa', label: 'Rasa', fontFamily: 'Rasa, serif' },
    { name: 'Mukta Vaani', label: 'Mukta Vaani', fontFamily: 'Mukta Vaani, sans-serif' },
    { name: 'Baloo Bhai', label: 'Baloo Bhai', fontFamily: 'Baloo Bhai 2, sans-serif' },
    { name: 'Anek Gujarati', label: 'Anek Gujarati', fontFamily: 'Anek Gujarati, sans-serif' },
   ];   

   export { malayalamFonts, hindiFonts, tamilFonts, kannadaFonts, bengaliFonts, teluguFonts, gujaratiFonts };